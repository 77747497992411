<template>
  <v-container
    id="extended-forms"
    fluid
    tag="section"
  >
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="yellow darken-1"
          icon="mdi-format-list-bulleted"
        >
          <template #after-heading>
            <div class="display-2 font-weight-light">
              {{ $tc('overview', 1) }}
            </div>
          </template>

          <v-data-table
            :items="users.data"
            :headers="users.headers"
            :footer-props="users.footer"
            :items-per-page="users.options.itemsPerPage"
            :loading="users.loading"
            :search.sync="users.search"
          >
            <template #top>
              <v-btn
                class="float-right ml-2"
                dark
                small
                color="primary"
                @click="getUsers()"
              >
                <v-icon dark>
                  mdi-cached
                </v-icon>
              </v-btn>
              <v-dialog
                v-model="userDialog"
                max-width="500px"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    small
                    class="float-right"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon left>
                      mdi-plus
                    </v-icon>
                    {{ $tc("new", 1) }}
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-form
                        ref="userForm"
                        v-model="valid"
                        lazy-validation
                      >
                        <v-row>
                          <v-col
                            cols="12"
                          >
                            <v-text-field
                              v-model="users.edit.name"
                              :label="$tc('name', 1)"
                              :disabled="users.action === 'delete'"
                              :rules="[users.rules.required, users.rules.countmin5, users.rules.countmax40]"
                              :error-messages="users.errors.name"
                              maxlength="40"
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                          >
                            <v-text-field
                              v-model="users.edit.email"
                              :label="$tc('email', 1)"
                              :disabled="users.action === 'delete'"
                              :rules="[users.rules.required, users.rules.email, users.rules.countmax60]"
                              :error-messages="users.errors.email"
                              maxlength="60"
                            />
                          </v-col>
                        </v-row>
                        <v-row v-if="users.action !== 'delete'">
                          <v-col
                            cols="12"
                          >
                            <v-text-field
                              v-if="users.edit.password !== null"
                              v-model="users.edit.password"
                              :label="$tc('password', 1)"
                              :rules="[users.rules.required, users.rules.countmin8, users.rules.countmax20]"
                              :error-messages="users.errors.name"
                              :type="users.showPassword ? 'text' : 'password'"
                              :append-icon="users.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                              maxlength="20"
                              @click:append="users.showPassword = !users.showPassword"
                            />
                          </v-col>
                        </v-row>
                        <v-row v-if="users.action !== 'delete' && users.edit.id !== $auth.user().id">
                          <v-col
                            cols="12"
                          >
                            <v-data-table
                              v-model="users.edit.roles"
                              :items="roles.data"
                              :headers="roles.headers"
                              item-key="id"
                              show-select
                              hide-default-header
                              hide-default-footer
                            >
                              <template #top>
                                <span class="caption font-weight-light text--disabled">{{ $tc('role', 2) }}</span>
                              </template>

                              <template #[`item.name`]="{ item }">
                                {{ $tc("roles." + item.name, 1) }}
                              </template>
                            </v-data-table>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="closeUser()"
                    >
                      {{ $tc('close', 1) }}
                    </v-btn>
                    <v-btn
                      v-if="users.action === 'delete'"
                      color="blue darken-1"
                      text
                      @click="purgeUser()"
                    >
                      {{ $tc('delete', 1) }}
                    </v-btn>
                    <v-btn
                      v-else
                      color="blue darken-1"
                      text
                      @click="saveUser()"
                    >
                      {{ $tc('save', 1) }}
                    </v-btn>
                  </v-card-actions>

                  <v-overlay
                    :value="loader"
                    absolute
                  >
                    <v-progress-circular indeterminate />
                  </v-overlay>
                </v-card>
              </v-dialog>
              <v-text-field
                v-model="users.search"
                append-icon="mdi-magnify"
                :label="$tc('search', 1)"
                hide-details
                single-line
                style="max-width: 250px;"
              />
              <v-divider class="mt-5" />
            </template>

            <template #[`item.roles`]="{ item }">
              <span
                v-for="(role, id) in item.roles"
                :key="role.id"
              ><span v-if="id > 0">, </span>{{ $tc("roles." + role.name, 1) }}</span>
            </template>

            <template #[`item.actions`]="{ item }">
              <v-icon
                small
                :disabled="!$auth.check(['customer/user/edit'])"
                class="mr-2"
                @click="editUser(item)"
              >
                mdi-pencil
              </v-icon>

              <v-icon
                small
                class="mr-2"
                :disabled="!$auth.check(['customer/user/delete']) || item.roles.some(o => o.name === 'customersuperadmin') || item.id === $auth.user().id"
                @click="deleteUser(item)"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
          <v-snackbar
            v-model="snackbar.active"
            :timeout="snackbar.timeout"
            :color="snackbar.color"
            rounded="pill"
            absolute
            centered
          >
            {{ snackbar.text }}

            <template #action="{ attrs }">
              <v-btn
                text
                fab
                small
                rounded
                v-bind="attrs"
                @click="snackbar.active = false; snackbar.text = ''"
              >
                <v-icon>
                  mdi-close
                </v-icon>
              </v-btn>
            </template>
          </v-snackbar>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Users',

    data: function () {
      return {
        store: this.$store,
        users: {
          data: [],
          headers: [
            {
              text: this.$tc('name', 1),
              value: 'name',
            },
            {
              text: this.$tc('email', 1),
              value: 'email',
            },
            {
              text: this.$tc('role', 2),
              value: 'roles',
            },
            {
              text: this.$tc('action', 2),
              value: 'actions',
              align: 'end',
            },
          ],
          footer: {
            itemsPerPageAllText: '-',
            itemsPerPageOptions: [5, 10, 25, -1],
            itemsPerPageText: '',
            pageText: '{0}-{1} / {2}',
          },
          options: {
            itemsPerPage: 25,
          },
          loading: true,
          search: '',
          dialog: false,
          action: '',
          index: -1,
          default: {
            id: null,
            name: '',
            email: '',
            password: '',
            roles: [],
          },
          edit: {
            id: null,
            name: '',
            email: '',
            password: '',
            roles: [],
          },
          showPassword: false,
          rules: {
            required: value => !!value || this.$tc('required', 1),
            countmin5: value => value.length >= 5 || this.$tc('lengthmin', 1) + 5,
            countmin8: value => value.length >= 8 || this.$tc('lengthmin', 1) + 8,
            countmax20: value => value.length <= 20 || this.$tc('lengthmax', 1) + 20,
            countmax40: value => value.length <= 40 || this.$tc('lengthmax', 1) + 40,
            countmax60: value => value.length <= 60 || this.$tc('lengthmax', 1) + 60,
            countmax255: value => value.length <= 255 || this.$tc('lengthmax', 1) + 255,
            email: value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              return pattern.test(value) || this.$tc('emailinvalid', 1)
            },
          },
          errors: {
            name: null,
            email: null,
            password: null,
          },
        },
        userDialog: false,
        roles: {
          data: [],
          headers: [
            {
              text: this.$tc('name', 1),
              value: 'name',
            },
          ],
        },
        valid: null,
        loader: false,
        snackbar: {
          active: false,
          text: '',
          color: 'error',
          timeout: 2000,
        },
      }
    },
    computed: {
      formTitle () {
        if (this.users.action === 'delete') return this.$tc('delete', 1)
        return this.users.index === -1 ? this.$tc('create', 1) : this.$tc('edit', 1)
      },
    },
    watch: {
      userDialog (val) {
        val || this.closeUser()
      },
    },
    mounted () {
      this.getUsers()
      this.getRoles()
    },
    methods: {
      getUsers () {
        var self = this
        self.users.loading = true
        self.users.data = []
        self.$http.get('/api/user?include=roles').then(function (response) {
          self.users.data = response.data
          self.users.loading = false
        }, function (response) {
          self.users.loading = false
        })
      },
      editUser (item) {
        this.users.index = this.users.data.indexOf(item)
        this.users.edit = Object.assign({}, item)
        this.users.edit.password = ''
        this.users.action = 'edit'
        this.userDialog = true
      },
      deleteUser (item) {
        this.users.index = this.users.data.indexOf(item)
        this.users.edit = Object.assign({}, item)
        this.users.action = 'delete'
        this.userDialog = true
      },
      closeUser () {
        this.userDialog = false
        this.$refs.userForm.resetValidation()
        this.$nextTick(() => {
          this.users.index = -1
          this.users.edit = Object.assign({}, this.users.default)
          this.users.action = ''
          this.users.errors.name = null
          this.users.errors.email = null
          this.users.errors.password = null
        })
      },
      saveUser () {
        var self = this
        if (self.users.index > -1) {
          self.$http.put('/api/user/' + self.users.edit.id, self.users.edit).then(function (response) {
            if (self.store.state.result === 422) {
              if (self.store.state.message.name) self.users.errors.name = [self.$t('invalids.' + self.store.state.message.contact[0].split('.').join(''))]
              if (self.store.state.message.email) self.users.errors.email = [self.$t('invalids.' + self.store.state.message.email[0].split('.').join(''))]
              if (self.store.state.message.password) self.users.errors.password = [self.$t('invalids.' + self.store.state.message.password[0].split('.').join(''))]
              self.snackbar.text = self.$tc('user', 1) + ' ' + self.$tc('savefailed', 1)
              self.snackbar.color = 'error'
            } else {
              Object.assign(self.users.data[self.users.index], response.data)
              if (self.users.edit.id === self.$auth.user().id) self.$auth.fetch()
              self.snackbar.text = self.$tc('user', 1) + ' ' + self.$tc('saved', 1)
              self.snackbar.color = 'primary'
              self.closeUser()
            }
            self.snackbar.active = true
            self.loader = false
          }, function (response) {
            self.snackbar.color = 'error'
            self.snackbar.text = self.$tc('user', 1) + ' ' + self.$tc('savefailed', 1)
            self.closeUser()
            self.loader = false
            self.snackbar.active = true
          })
        } else {
          self.$http.post('/api/user', self.users.edit).then(function (response) {
            if (self.store.state.result === 422) {
              if (self.store.state.message.name) self.users.errors.name = [self.$t('invalids.' + self.store.state.message.contact[0].split('.').join(''))]
              if (self.store.state.message.email) self.users.errors.email = [self.$t('invalids.' + self.store.state.message.email[0].split('.').join(''))]
              if (self.store.state.message.password) self.users.errors.password = [self.$t('invalids.' + self.store.state.message.password[0].split('.').join(''))]
              self.snackbar.color = 'error'
              self.snackbar.text = self.$tc('user', 1) + ' ' + self.$tc('savefailed', 1)
            } else {
              self.users.data.push(response.data)
              self.snackbar.color = 'primary'
              self.snackbar.text = self.$tc('user', 1) + ' ' + self.$tc('saved', 1)
              self.closeUser()
            }
            self.loader = false
            self.snackbar.active = true
          }, function (response) {
            self.snackbar.color = 'error'
            self.snackbar.text = self.$tc('user', 1) + ' ' + self.$tc('savefailed', 1)
            self.closeUser()
            self.loader = false
            self.snackbar.active = true
          })
        }
      },
      purgeUser () {
        var self = this
        self.$http.delete('/api/user/' + self.users.edit.id).then(function (response) {
          self.users.data.splice(self.users.index, 1)
          self.snackbar.color = 'primary'
          self.snackbar.text = self.$tc('user', 1) + ' ' + self.$tc('deleted', 1)
          self.closeUser()
          self.loader = false
          self.snackbar.active = true
        }, function (response) {
          self.snackbar.color = 'error'
          self.snackbar.text = self.$tc('user', 1) + ' ' + self.$tc('deletefailed', 1)
          self.closeUser()
          self.loader = false
          self.snackbar.active = true
        })
      },
      getRoles () {
        var self = this
        self.roles.data = []
        self.$http.get('/api/role').then(function (response) {
          self.roles.data = response.data
        }, function (response) {
        })
      },
    },
  }
</script>
